import React from 'react';
import { WPImage } from '@/data/wp/operations/queries/fragments/image';
import styled from '@mui/system/styled';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Image from 'next/image';
import theme from '@/mui/theme';
import Box from '@mui/system/Box';
import { sanitize } from '@/utils/miscellaneous';
import OmniLink from './OmniLink';

const StyledLabel = styled(Typography)({
  backgroundColor: theme.palette.secondary.main,
  position: 'absolute',
  color: '#fff',
  textAlign: 'center',
  top: '18px',
  zIndex: 800,
  padding: '0.3em 1.5em',
  fontSize: '13px',
  lineHeight: '18px',
  fontWeight: '500',
  borderRadius: '0 12px 12px 0',
  '.horizontal &': {
    left: 0,
  },
  [theme.breakpoints.down('sm')]: {
    top: '28px',
    padding: '0.2em 1.3em'
  }
})

export enum WhiteCardImageSize {
  original = 'original',
  medium = 'medium', // 255
  small = 'small', // 130
}

export interface WhiteCardProps {
  bigTitle?: boolean,
  horizontal?: boolean,
  horizontalImageSize?: WhiteCardImageSize,
  label?: string,
  img?: WPImage,
  placeholderImage?: any,
  occhiello?: string,
  title?: string,
  text?: string,
  url: string
  searchVariant?: boolean
}

const WhiteCard: React.FC<WhiteCardProps> = ({ bigTitle = false, horizontal = false, horizontalImageSize = WhiteCardImageSize.small, label, img, placeholderImage, occhiello, title, text, url, searchVariant = false }) => {
  let horizontalImageWidth = 0;
  let horizontalImageHeight = 0;
  switch (horizontalImageSize) {
    case WhiteCardImageSize.original:
      if (img) {
        horizontalImageWidth = img.mediaDetails.width;
        horizontalImageHeight = img.mediaDetails.height;
      }
      break;
    case WhiteCardImageSize.medium:
      horizontalImageWidth = horizontalImageHeight = 255;
      break;
    case WhiteCardImageSize.small:
      horizontalImageWidth = horizontalImageHeight = 130;
      break;
  }

  let imageElement;
  if (horizontal) {
    if (img?.mediaItemUrl) {
      imageElement = <Image layout="fill" objectFit="cover" src={img.mediaItemUrl} alt={img.altText} />
    } else if (placeholderImage) {
      imageElement = <Image layout="fill" objectFit="cover" src={placeholderImage} alt="" aria-hidden="true"></Image>
    }
  } else {
    if (img?.mediaItemUrl) {
      imageElement = <Image layout="responsive" src={img.mediaItemUrl} width={img.mediaDetails.width} height={img.mediaDetails.height} alt={img.altText} />
    } else if (placeholderImage) {
      imageElement = <Image layout="responsive" src={placeholderImage} alt="" aria-hidden="true"></Image>
    }
  }

  return (
    <Card className={horizontal ? 'horizontal' : ''} variant="outlined" sx={{
      borderRadius: searchVariant ? '0' : '6px',
      border: searchVariant ? '0' : ''
    }}>
      <CardActionArea LinkComponent={OmniLink} href={url} sx={{
        '.horizontal &': {
          display: 'flex',
          justifyContent: 'flex-start',
          // alignItems: 'flex-start',
        },
      }}>
        {imageElement && <>
          {!horizontal &&
            <Box sx={{
              position: {
                xs: 'relative',
              }
            }}>
              {label &&
                <StyledLabel>{label}</StyledLabel>
              }
              {imageElement}
            </Box>
          }
          {horizontal &&
            <Box sx={{
              position: {
                xs: 'relative',
              },
              width: {
                md: horizontalImageWidth,
                xs: '130px',
              },
              height: {
                md: horizontalImageHeight,
                xs: '130px',
              },
              minWidth: {
                md: horizontalImageWidth,
                xs: '130px',
              },
              minHeight: {
                md: horizontalImageHeight,
                xs: '130px',
              }
            }}>
              {label &&
                <StyledLabel>{label}</StyledLabel>
              }
              {imageElement}
            </Box>
          }
        </>}
        <CardContent sx={{
          '.horizontal &': {
            pt: 3,
            padding: {
              md: searchVariant ? '16px 16px 16px 30px' : '24px 16px 16px',
              xs: '16px',
            },
            alignItems: 'center'
          },
          [theme.breakpoints.up('md')]: {
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignSelf: 'flex-start',
          },
        }}>
          {occhiello &&
            <Typography className={searchVariant ? 'search-text' : undefined } sx={{
              color: 'primary.main',
              width: '100%',
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: '600',
              letterSpacing: '0px',
            }}>{occhiello}</Typography>
          }
          {title &&
            <Typography className={searchVariant ? 'search-text' : undefined } sx={{
                width: '100%',
                minHeight: "48px",
                '.horizontal &': {
                  height: 'auto',
                  minHeight: searchVariant ? '0' : '66px',
                },
                fontSize: bigTitle ? '18px' : '16px',
                lineHeight: bigTitle ? '26px' : '24px',
                fontWeight: '600',
                letterSpacing: '0.1px',
                [theme.breakpoints.down('sm')]: {
                  height: '72px'
                }
              }}
              dangerouslySetInnerHTML={{__html: sanitize(title)}}
            />
          }
          {text &&
            <Typography component="div" className={searchVariant ? 'search-text' : undefined }
              sx={{
                width: '100%',
                color: '#1C1C1C',
                fontSize: '15px',
                lineHeight: '22px',
                letterSpacing: '-0.1px',
              }}
              dangerouslySetInnerHTML={{__html: sanitize(text)}}
            />
          }
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default WhiteCard;
