import { Dispatch, Reducer, useEffect } from 'react';
import { AsyncActionHandlers, useReducerAsync } from "use-reducer-async";
import NProgress from 'nprogress';
// import { ParsedUrlQuery } from "querystring";

/* BEGIN: MOCK */

// import { posts as _mockAPIPost, projects as _mockAPIProjects } from 'mocks/wp/graphql/post';
// import { generateProductMockForCardList } from 'src/mock/generator';
import { getSearchData } from '@/data/wp/sdk';
import useTranslation from 'next-translate/useTranslation';
import { getProductById, getProductByPartNumber, getProductBySearchTerm } from '@/data/hcl/sdk';
import { getStore, storeId, langId } from "./storeInfo";
import { useUIError } from './errors';
import { mapProductItemToCardList } from '@/data/hcl/sdk/utils';

// const _mockDataProducts = generateProductMockForCardList(12);

/* END: MOCK */

export interface StateDataGroup {
  pending: boolean,
  items: any[] | null
}

export interface State {
  query: string | null,
  error: {[key: string]: any} | null,
  storeId: string | null,
  langId: string | null,
  results: {[key: string]: StateDataGroup},
}

const initialState: State = {
  query: null,
  error: null,
  storeId: null,
  langId: null,
  results: {
    products: { pending: false, items: null },
    projects: { pending: false, items: null },
    contents: { pending: false, items: null }
  }
}

enum AsyncActionKind {
  SEARCH_CMS = 'SEARCH_CMS',
  SEARCH_COMMERCE = 'SEARCH_COMMERCE',
}

type AsyncAction = {
  type: AsyncActionKind;
  payload: any
}

enum InnerActionKind {
  SEARCH_CMS_START = 'SEARCH_CMS_START',
  SEARCH_CMS_END = 'SEARCH_CMS_END',
  SEARCH_CMS_ERROR = 'SEARCH_CMS_ERROR',
  SEARCH_COMMERCE_START = 'SEARCH_COMMERCE_START',
  SEARCH_COMMERCE_END = 'SEARCH_COMMERCE_END',
  SEARCH_COMMERCE_ERROR = 'SEARCH_COMMERCE_ERROR',
}

interface InnerAction {
  type: InnerActionKind
  payload?: any
}

export enum ActionKind {
  SEARCH = 'SEARCH',
}

export interface Action {
  type: ActionKind
  payload?: any
}

const asyncActionHandlers: AsyncActionHandlers<Reducer<State, InnerAction>, AsyncAction> = {
  /* @ts-ignore */
  SEARCH_CMS: ({ dispatch }) => async (action) => {
    dispatch({ type: InnerActionKind.SEARCH_CMS_START });
    try {
      let projectsData: any[] = [];
      let contentsData: any[] = [];

      if (process.env.NEXT_PUBLIC_CMS_ENABLE !== 'false') {

        // ENHANCEMENT: ricerca nei campi custom (e nelle fascie)
        const { data } = await getSearchData(action.payload.lang, action.payload.text);

        data?.pages?.edges?.map((page: any) => {
          contentsData.push(page);
        });

        data?.posts?.edges?.map((post: any) => {
          const projectCategory = post?.node?.categories?.edges?.find((cat: any) => cat.node.slug === action.payload.projectsCategorySlug);
          if (projectCategory) {
            projectsData.push(post);
          } else {
            contentsData.push(post);
          }
        });
      }

      dispatch({ type: InnerActionKind.SEARCH_CMS_END, payload: {
        projects: projectsData,
        contents: contentsData,
      }});
    } catch (e) {
      dispatch({ type: InnerActionKind.SEARCH_CMS_ERROR, payload: {
        errorObj: e
      }});
    }
  },

  /* @ts-ignore */
  SEARCH_COMMERCE: ({ dispatch }) => async (action) => {
    dispatch({ type: InnerActionKind.SEARCH_COMMERCE_START });
    try {
      const nuLocale = action.payload.lang || process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'it';
      await getStore(nuLocale);

      //console.log('storeId: %s, langId: %s, searchTerm: %s', storeId,langId,action.payload.text);

      let { data } = await getProductBySearchTerm(storeId, langId, action.payload.text);

      let productList = data?.productViewFindProductsBySearchTerm?.catalogEntryView;
      if (productList && productList.length > 0) {
        /* @ts-ignore */
        productList = await Promise.all(productList.map(mapProductItemToCardList.bind(null,storeId,'',{},'',langId)));
        dispatch({ type: InnerActionKind.SEARCH_COMMERCE_END, payload: {
          products: productList,
          storeId: storeId,
          langId: langId
        }});
      } else if(productList && productList.length === 0){
        //se non ci sono prodotti, provo a effettuare ricerca per troavre sku
        let { data } = await getProductByPartNumber(storeId, langId,action.payload.text);

        let skuList = data?.productViewFindProductByPartNumber?.catalogEntryView;
        if(skuList && skuList.length > 0){
          let { data } = await getProductById(storeId, langId,skuList[0].parentCatalogEntryID);

          let productList = data?.productViewFindProductById?.catalogEntryView;
          if (productList) {
            /* @ts-ignore */
            productList = await Promise.all(productList.map(mapProductItemToCardList.bind(null,storeId,'',{},'',langId)));
            dispatch({ type: InnerActionKind.SEARCH_COMMERCE_END, payload: {
              products: productList,
              storeId: storeId,
              langId: langId
            }});
          }
        } else {
          dispatch({ type: InnerActionKind.SEARCH_COMMERCE_END, payload: {
            products: skuList,
            storeId: storeId,
            langId: langId
          }});
        }
      } else {
        dispatch({ type: InnerActionKind.SEARCH_COMMERCE_ERROR, payload: { 
          errorObj: 'Missing data in the response: data?.productViewFindProductsBySearchTerm?.catalogEntryView' 
        }});
      }
    } catch (e) {
      dispatch({ type: InnerActionKind.SEARCH_COMMERCE_ERROR, payload: { 
        errorObj: e 
      } });
    }
  },
};

// Our reducer function that uses a switch statement to handle our actions
function reducer(state: State, action: InnerAction | Action): State {
  const { type, payload } = action;
  switch (type) {
    case InnerActionKind.SEARCH_CMS_START:
      return {
        ...state,
        results: {
          products: {
            ...state.results.products,
          },
          projects: {
            ...state.results.projects,
            pending: true,
          },
          contents: {
            ...state.results.contents,
            pending: true,
          },
        }
      };

    case InnerActionKind.SEARCH_CMS_END:
      return {
        ...state,
        results: {
          products: {
            ...state.results.products,
          },
          projects: {
            items: payload.projects,
            pending: false,
          },
          contents: {
            items: payload.contents,
            pending: false,
          },
        }
      };

    case InnerActionKind.SEARCH_CMS_ERROR:
      return {
        ...state,
        error: payload.errorObj,
        results: {
          products: {
            ...state.results.products,
          },
          projects: {
            items: null,
            pending: false,
          },
          contents: {
            items: null,
            pending: false,
          },
        }
      };

    case InnerActionKind.SEARCH_COMMERCE_START:
      return {
        ...state,
        results: {
          products: {
            ...state.results.products,
            pending: true,
          },
          projects: {
            ...state.results.projects,
          },
          contents: {
            ...state.results.contents,
          },
        }
      };

    case InnerActionKind.SEARCH_COMMERCE_END:
      return {
        ...state,
        storeId: payload.storeId,
        langId: payload.langId,
        results: {
          products: {
            items: payload.products,
            pending: false,
          },
          projects: {
            ...state.results.projects,
          },
          contents: {
            ...state.results.contents,
          },
        }
      };

    case InnerActionKind.SEARCH_COMMERCE_ERROR:
      return {
        ...state,
        error: payload.errorObj,
        results: {
          products: {
            items: null,
            pending: false,
          },
          projects: {
            ...state.results.projects,
          },
          contents: {
            ...state.results.contents,
          },
        }
      };

    case ActionKind.SEARCH:
      return {
        ...state,
        error: null,
        query: payload,
      };

    default:
      return state;
  }
}

export const useAsyncSearch: () => [State, Dispatch<Action | AsyncAction>] = () => {
  const { t, lang } = useTranslation('common');
  const { addError } = useUIError();
  const projectsCategorySlug = t(`post-cat-slug-projects`, {}, {default: 'projects'})

  const [state, dispatch] = useReducerAsync<
    Reducer<State, InnerAction | Action>,
    AsyncAction,
    AsyncAction | Action
  >(
    reducer,
    initialState,
    asyncActionHandlers,
  );

  useEffect(() => {
    if (state.query && state.query.length >= 3) {
      dispatch({ type: AsyncActionKind.SEARCH_CMS, payload: { lang, text: state.query, projectsCategorySlug }});
      dispatch({ type: AsyncActionKind.SEARCH_COMMERCE, payload: { lang, text: state.query } });
    }
  }, [state.query, lang, projectsCategorySlug, dispatch])

  useEffect(() => {
    if (state.results.products.pending || state.results.projects.pending || state.results.contents.pending) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [state.results.products.pending, state.results.projects.pending, state.results.contents.pending]);

  useEffect(() => {
    if (state.error) {      
      addError({ message: t(`search-ui-error-message`, {}, {default: 'There was an error while retrieving the search result.'})  });
    }
  }, [addError, state.error, t]);

  return [state, dispatch];
}
