import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@mui/system/styled';
import theme from '@/mui/theme';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { CloseCircleBlackIcon } from '../icons';
import WhiteCard from '@/components/ui/WhiteCard';
import Button from '@mui/material/Button';
import { getIconComponentByName } from '@/utils/icons-map';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';
import { goToSearchResultPage } from '@/utils/router';
import { useRouter } from 'next/router';
import { ActionKind, useAsyncSearch } from '@/utils/search';
import { mapPostAPIToCardList } from '@/data/wp/sdk/utils';

import placeholderProductGray from '@/public/placeholder-product-gray.svg';
import placeholderProductTransparent from '@/public/placeholder-product-transparent.svg';

const StyledButton = styled(Button)({
  backgroundColor: '#ff5c04',
  borderRadius: '5px',
  padding: '16px 40px',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: 'normal',
  letterSpacing: '0',
  color: '#ffffff'
})

const StyledCloseIcon = styled(CloseCircleBlackIcon)({
  width: '24px',
  height: '24px',
  color: '#202020',
  cursor: 'pointer',
})

const StyledBox = styled(Box)({
  position: 'relative',
  backgroundColor: '#ffffff',
  border: '1px solid #979797',
  padding: '0',
  margin: '0',
  maxWidth: '100%',
  width: '100%',
  minHeight: '100vh',
})

const StyledModal = styled(ModalUnstyled)({
  position: 'absolute',
  top: '0',
  maxWidth: '100%',
  bottom: 'unset',
  zIndex: '1300',
  right: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.MuiModal-hidden': {
    display: 'none',
  }
})

const StyledResultFound = styled(Typography)({
  color: '#888f9d',
  fontSize: "15px",
  letterSpacing: "-0.1px",
  lineHeight: "22px",
});

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.5;
  -webkit-tap-highlight-color: transparent;
`;

const StyledOutlinedInput = styled(OutlinedInput)({
  border: '0',
  borderRadius: '15px',
  backgroundColor: '#ffffff',
  '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0
  },
  '.MuiInputBase-input': {
    height: 'auto',
    color: '#000000',
    fontSize: "34px",
    letterSpacing: "-0.23px",
    lineHeight: "22px",
    padding: '15px 6px 15px 23px',
  },
  '.MuiButtonBase-root': {
    marginRight: '0',
  },
  'svg': {
    width: '28px',
    height: '28px',
    color: '#000000',
    opacity: '0.8'
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiInputBase-input': {
      padding: '6px 6px 6px 15px',
      fontSize: '22px',
      lineHeight: '32px',
    },
    '.MuiButtonBase-root': {
      marginRight: '-10px'
    },
    'svg': {
      width: '24px',
      height: '24px',
    }
  }
})

interface SearchModalProps {
  isOpen: boolean
  onClose: () => void
}

const SearchModal: React.FC<SearchModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [state, dispatch] = useAsyncSearch();
  const [searchText, setSearchText] = useState<string>('');
  const [labelResultFound, setLabelResultFound] = React.useState<string>('');

  const itemCount = (state.results.products.items?.length || 0) + (state.results.projects.items?.length || 0) + (state.results.contents.items?.length || 0)

  useEffect(() => {
    const startSearch = setTimeout(() => {
      dispatch({ type: ActionKind.SEARCH, payload: searchText });
    }, 1000);
    return () => clearTimeout(startSearch);
  },[searchText,dispatch])

  useEffect(() => {
    if(searchText && searchText !== '')
      setLabelResultFound(t('find-results', { count: itemCount }));
  }, [t, searchText, itemCount]);

  /* const handleChangeInput = (event: { target: { value: SetStateAction<string>; }; }) => {
    const text = event.target.value
    setSearchText(text);

    if (text && text.length >= 3) {
       if(startSearch)
          clearTimeout(startSearch);
      startSearch = setTimeout(() => {
        dispatch({ type: ActionKind.SEARCH, payload: text });
      }, 5000);
    }
  } */

  const handleSubmitSearch = () => {
    if (searchText.length >= 3) {
      onClose();
      goToSearchResultPage(router, searchText);
    }
  }

  // Disattivato perchè inconsistente (per concorrenza con le chiamate a asincrone che popolano il risultato)
  // useEffect(() => {
  //   const reg = new RegExp(`(${searchText})`, 'gi');

  //   /** before remove mark if already present inside the text */
  //   document.querySelectorAll('mark')!.forEach((element) => {
  //     const oldMarkWord = element.innerHTML;
  //     return element.outerHTML = oldMarkWord;
  //   });

  //   /** if searched word is present so mark word inside the page */
  //   document.querySelectorAll('.search-text')!.forEach((element) => {
  //     if (searchText.length > 1) {
  //       return element.innerHTML = element.innerHTML.replaceAll(reg, '<mark>$1</mark>');
  //     }
  //   });
  // }, [searchText]);

  const handleKeyToSubmit = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      handleSubmitSearch();
    }
  }

  const handleClose = () => {
    onClose();
    setSearchText('');
  }

  return <StyledModal
    aria-labelledby="unstyled-modal-title"
    aria-describedby="unstyled-modal-description"
    open={isOpen}
    onClose={handleClose}
    components={{
      Backdrop: Backdrop
    }}
    disableScrollLock={true}
  >
    <StyledBox>
      <Box sx={{ position: 'relative', py: 3, backgroundColor: '#f1f1f1' }}>
        <Container>
          <Grid container rowSpacing={0}>
            <Grid item xs={10}>
              <FormControl variant="standard" fullWidth>
                <StyledOutlinedInput
                  id="search"
                  type="text"
                  autoFocus={true}
                  value={searchText}
                  error
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyPress={handleKeyToSubmit}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('search-icon', {}, {default: 'Search icon'})}
                        onClick={handleSubmitSearch}
                        onMouseDown={handleSubmitSearch}
                        edge="end"
                      >
                        {getIconComponentByName('search')}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder={t('header-search-placeholder', {})}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} display="flex" alignItems="center" justifyContent="end">
              <StyledCloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container>
        <Grid container rowSpacing={0} sx={{ position: 'relative' }}>
          <Grid item xs={0} sm={2} mt={{sm: 7, xs: 3}} />
          <Grid item xs={12} sm={8} mt={{sm: 7, xs: 3}}>
            {Object.keys(state.results).map((type: string, typeIndex: number) => {
              const typeState = state.results[type];
              const storeId = state["storeId"];
              const langId = state["langId"];
              return <SearchModalGroup key={typeIndex} type={type} items={typeState.items} storeId={storeId} langId={langId}></SearchModalGroup>
            })}
          </Grid>
          <Grid item xs={0} sm={2} mt={{sm: 7, xs: 3}} />

          <Grid item xs={0} sm={2} />
          {itemCount > 0 &&
            <Grid item xs={12} sm={8} mt={4} py={4.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderTop: '2px solid #888f9d', paddingLeft: '0!important', marginLeft: { sm: 0, xs: '32px'} }}>
              <StyledButton onClick={handleSubmitSearch}>
                {t('header-search-all-results', {}, {default: 'All results'})}
              </StyledButton>
            </Grid>
          }
          {itemCount === 0 &&
            <Grid item xs={12}>
              <StyledResultFound>{labelResultFound}</StyledResultFound>
            </Grid>
          }
          <Grid item xs={0} sm={2} />
        </Grid>
      </Container>
    </StyledBox>
  </StyledModal>
}

export default SearchModal;

interface SearchModalGroupProps {
  type: string,
  items: any,
  storeId: any,
  langId: any
}

const SearchModalGroup: React.FC<SearchModalGroupProps> = ({ type, items }) => {
  if (!items) {
    return <></>
  }
  const cmsContent = type === 'projects' || type === 'contents';
  const showEmptyImage = type === 'contents';
  const firstItems = items.slice(0, 10);
  const firstItemsData = cmsContent ? firstItems?.map(mapPostAPIToCardList): firstItems;

  return firstItemsData?.map((item: any, itemIndex: number) => {
    return (
      <Box key={`${type}-${itemIndex}`} mb={2.6}>
        <WhiteCard {...item} img={showEmptyImage ? undefined : item.img} searchVariant horizontal placeholderImage={showEmptyImage ? placeholderProductTransparent : placeholderProductGray}></WhiteCard>
      </Box>
    )
  })
}

